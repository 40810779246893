<!-- 产品信息 -->
<template>
    <div class="detail">
        <topnav active="detail"></topnav>
        <topmenu active="detail"/>
        <div class="detail_wrap public_width">
            <topbread active="detail" :first="first" :second="second" :first_id="first_id" :second_id="second_id" :pro="pro" v-if="bread_flag"></topbread>
            <div class="home_top d-flex">
                <div class="home_banner">
                    <!--<img class="big" :src="img_big" />-->
                    <bigimg class="big" :images="img_big" v-if="img_big"></bigimg>
                    <img class="sample" src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202112/14/%E9%A3%9E%E4%B9%A620211216-135814.png" v-if="info.purchase_limit && info.purchase_limit > 0">
                    <div class="glass" @click="preview()" v-if="img_big">
                        <i class="iconfont icon-fdj"></i>
                    </div>
                    <div class="detail_banner d-between">
                        <p class="action d-center" @click="prev()"><i class="prev iconfont icon-xz"></i></p>
                        <el-carousel ref="sale" class="show-img" trigger="click" :initial-index="0" height="107px" :arrow="never" :autoplay="false" :loop="false">
                            <div class="show-item">
                                <el-carousel-item class="d-between"  v-for="item in banner_list" :key="item">
                                    <div class="carousel_box d-flex" v-for="(v) in item" :key="v" @click="change_img(v)">
                                        <img class="banner_img" :class="img_big == v ? 'active' : ''" :src="v" />
                                    </div>
                                </el-carousel-item>
                            </div>
                        </el-carousel>
                        <p class="action d-center" @click="next()"><i class="next iconfont icon-xy" ></i></p>
                    </div>
                </div>
                <div class="home_info">
                    <h2 class="title d-flex">
                        <span class="name">{{ info.name }}</span>
                        <ul class="d-flex"> 
                            <li class="tag" v-for="v in info.coupons" :key="v.id">满{{v.threshold_money}}减{{v.dec_money}}</li>
                            <li class="dedu">E币可抵扣</li>
                        </ul>
                    </h2>

                    <div class="board">
                        <span class="board_title">规格型号</span>
                        <span class="symbol">：</span>
                        <p class="ctx">{{info.standard}}</p>
                    </div>

                    <div class="board">
                        <span class="board_title">封装</span>
                        <span class="symbol">：</span>
                        <p class="ctx">{{info.package}}</p>
                    </div>

                    <div class="board" v-if="info.brand">
                        <span class="board_title">品牌</span>
                        <span class="symbol">：</span>
                        <p class="ctx">{{info.brand}}</p>
                    </div>

                    <div class="board">
                        <span class="board_title">商品类别</span>
                        <span class="symbol">：</span>
                        <p class="ctx">{{info.second_category && info.second_category.name ? info.second_category.name : ''}}</p>
                    </div>

                    <div class="board">
                        <span class="board_title" v-if="info.databook_name">数据手册</span>
                        <span class="symbol" v-if="info.databook_name">：</span>
                        
                        <div class="data_box d-between">
                            <p class="data d-flex" @click="down_book()" v-if="info.databook_name"><i class="t-icon t-icon-a-zu1622"></i><span>{{info.databook_name}}</span></p>
                            <p v-else></p>
                            
                            <!-- <p class="get">供应商：<span>{{info.supplier}}</span></p> -->
                        </div>
                    </div>

                    <div class="normal d-flex">
                        <span class="price">价格：</span>
                        <ul class="price_list">
                            <li v-for="(v,k) in info.price_ladder" :key="v">
                                <span class="price_num">{{k}}+:</span>
                                <span class="price_per">￥{{v}}/个</span>
                            </li>
                        </ul>
                        <div class="store">库存：{{info.stock}}</div>
                    </div>

                    <div class="board num d-between">
                        <div class="d-flex">
                            <span class="board_title">数量</span>
                            <span class="symbol">：</span>
                            <div class="num-box d-flex">
                                <input type="number" :class="dis ? 'dis' : ''" v-model="num" @blur.stop="change_num()" :disabled="dis"/>
                                <div class="btns d-flex">
                                    <button @click.stop="num_add()">+</button>
                                    <button @click.stop="num_reduce()">-</button>
                                </div>
                            </div>
                            <div class="start_box">
                            
                                <div class="start" v-if="info.purchase_limit > 0"> 单人限购：<span id="house">{{info.purchase_limit}}</span></div>
                                <!--<div class="start" v-if="info.min_num > 0"> 起订量：<span id="house">{{info.min_num}}</span></div>-->
                            </div>
                        </div>

                        <el-popover placement="left-end" :width="200" trigger="hover" content="若库存不足可联系平台代购">
                            <template #reference>
                                <p class="num_buy d-flex" @click="serve()"><i class="t-icon t-icon-dgpt"></i><span>平台代购</span></p>
                            </template>
                        </el-popover>
                    </div>

                    <div class="board num_total">总额：<span>￥{{price}}</span></div>

                    <div class="tips">
                        <i class="t-icon t-icon-a-lujing12"></i>
                        <p>商品价格包含税费，平台所有商品均不包邮，默认顺丰快递。成交价格可能会上下浮动，交易以当天成交价为准。一经售出，无质量问题不予退换</p>
                    </div>

                    <div class="show-btns d-flex">
                        <button class="join-btn" :class="dis ? 'dis' : ''" @click="join()" :disabled="dis"> 加入购物车</button>
                        <button class="add-btn" :class="dis ? 'dis' : ''" @click="buy()" :disabled="dis">立即购买</button>
                        <p class="rent" @click="to_smt()">社区可提供SMT打样服务</p>
                    </div>

                    <div class="show-other">产品信息若有问题，<span @click="rent()">欢迎纠错</span>有机会获取E币哦~</div>
                </div>
            </div>
            <el-tabs class="home_bottom" v-model="tab_active">
                <el-tab-pane label="商品详情" name="detail">
                    <div class="home_goods">
                        <div v-if="info.filters && info.filters.length > 0 || info.desc ">
                        
                            <div class="intro">
                                <span class="intro_title">产品介绍:</span>
                                <p class="intro_txt">{{info.desc}}</p>
                            </div>
                            <ul class="data" v-if="info.filters && info.filters.length > 0">
                                <li class="data_head">
                                    <span class="data_title">属性</span>
                                    <span class="data_val">参数值</span>
                                </li>  
                                <li class="data_body" v-for="(v) in info.filters" :key="v.filter_id">
                                    <span class="data_title">{{v.filter_name}}：</span>
                                    <span class="data_val">{{ v.value }}</span>
                                </li>
                            </ul>
                        </div>
                        <div class="bgc_empty" v-else>
                            <img src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202111/13/%E9%A3%9E%E4%B9%A620211117-132058.png" alt="百纳芯城"/>
                            <p>暂无详情</p>
                        </div>

                        <!--<div class="data_other" v-if="info.instruction">
                            <div v-html="info.instruction"></div>
                        </div>-->
                    </div>
                </el-tab-pane>
                <el-tab-pane label="数据手册" name="book" v-if="info.databook_name">
                    <div class="book_box d-center">
                        <p class="book_data d-center" @click="down_book()"><i class="t-icon t-icon-a-zu1622"></i><span class="book_name">{{info.databook_name}}</span><span class="book_down">下载</span></p>
                    </div>
                    <el-carousel height="1000px" ref="book" :initial-index="0" :loop="false" v-if="book_img && book_img.length>0">
                        <el-carousel-item v-for="item in book_img" :key="item">
                            <img class="book_img" :src="item">
                        </el-carousel-item>
                    </el-carousel>

                </el-tab-pane>
            </el-tabs>
        </div>
        <foot active="detail"/>
        <toolbar />

        <!--信息纠错-->
        <el-dialog
            custom-class="modal_err"
            v-model="rent_show"
            :before-close="handleClose"
        >
            <template v-slot:title>
                <div class="dialog-title">信息纠错</div>
            </template>  
            <div class="dialog-content">
                <p class="err_title">如果您发现产品描述的信息错误，请及时联系反馈，一旦平台确认反馈属实，我们将赠送您3E币。</p>
                <el-form
                    :model="form"
                    ref="form_err"
                    :rules="err_rules"
                    label-width="80px"
                >
                    <el-form-item label="商品名称:">
                        <!-- <p>{{info.name}}</p> -->
                        <el-input v-model="info.name" readonly ></el-input>
                    </el-form-item>

                    <el-form-item label="纠错位置:" prop="locate">
                        <el-select v-model="form.locate">
                            <el-option value="商品参数" label="商品参数"></el-option>
                            <el-option value="数据手册" label="数据手册"></el-option>
                            <el-option value="商品价格" label="商品价格"></el-option>
                        </el-select>

                    </el-form-item>

                    <el-form-item label="上传图片:">
                        <div class="demo-upload-list" v-for="(v,k) in form.thumbs" :key="k" >
                            <img :src="v">
                            <div class="demo-upload-list-cover">
                                <i class="iconfont icon-chakan" @click="handleView(v)"></i>
                                <i class="iconfont icon-shanchu" @click="handleRemove(v,k)"></i>
                            </div>
                        </div>
                        <el-upload
                            ref="up"
                            v-if="form.thumbs.length < 4"
                            class="detail_up"
                            action=""
                            list-type="picture-card"
                            :show-file-list="false"
                            :file-list="form.thumbs"
                            :http-request="img_upload"
                            limit="5"
                            accept=".jpg,.jpeg,.png"
                        >
                            <el-icon><plus /></el-icon>
                        </el-upload>
                        
                        
                        <el-dialog v-model="dialogVisible">
                            <img class="dialog_img" :src="dialogImageUrl" alt="" />
                        </el-dialog>
                    </el-form-item>

                    <el-form-item label="详细说明:" prop="desc">
                        <el-input v-model="form.desc" type="textarea" placeholder="请详细说明具体需要调整的内容"></el-input>
                    </el-form-item>
                </el-form>

                
            </div>
            <template v-slot:footer>
                <div class="dialog-footer">
                    <el-button @click="rent_show = false">取 消</el-button>
                    <el-button class="sure" @click="store_error()">确 定</el-button>
                </div>
            </template>  
        </el-dialog>

        <!-- 客服弹窗 -->
        <el-dialog custom-class="modal_serve" v-model="serve_show">
            <template v-slot:title>
                <div class="dialog-title">扫码联系平台代购</div>
            </template>  
            <div class="dialog-content d-center">
                <img class="serve_img" src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202111/23/%E9%A3%9E%E4%B9%A620211124-093232.png">
            </div>
        </el-dialog>

    </div>
</template>

<script>
import topnav from "@/components/Nav.vue";
import foot from "@/components/Footer.vue";
import toolbar from "@/components/Toolbar.vue";
import topmenu from "@/components/Menu.vue";
import topbread from "@/components/Bread.vue";
import { getData, postData } from "@/api/user";
import {  toRefs, reactive, ref, onMounted, nextTick } from "vue";
import { useStore } from "vuex";
import { useRouter,useRoute } from "vue-router";
import { ElMessage } from 'element-plus'
import { Plus } from '@element-plus/icons'
import bigimg from "@/components/BigImg.vue";



export default {
    name: "detail",
    setup() {
        const store = useStore() 
        const router = useRouter()
        const route = useRoute()
        const sale = ref(null)
        const book = ref(null)
        const form_err = ref(null)
        const up = ref(null)
        store.commit('setCount');


        let state = reactive ({
            ali_oss: store.state.ali_oss,
            userid: store.state.userId,
            id: route.query.id,

            info: {},
            item: {}, // 当前规格
            // 面包屑
            first: '',
            second: '',
            first_id:'',
            second_id: '',
            pro:'',
            bread_flag: true,
            // 购物车数量
            count: 0,
            // 轮播
            banner: [],
            banner_list:[],
            banner_idx:0,
            img_big: '',
            // 售价
            price: 0,
            // 产品数量
            num: 1,
            min_num:0,
            // 商品详情
            tab_active: "detail",
            // 数据手册
            book_img:[],
            //立即购买
            dis: false,
            // 纠错
            rent_show: false,
            // 客服
            serve_show: false,
            form:{
                thumbs:[],
                locate: '',
                desc:''
            },
            err_rules: {
                locate: [{ required: true, message: "请选择纠错位置", trigger: "change" }],
                desc: [{ required: true, message: "请输入详细说明", trigger: "blur" }],
            },
            // 上传
            dialogImageUrl: '',
            dialogVisible: false,
        })

        let get_info = () => {
            
            getData({
                url: `api/goods/index/${state.id}`,
                data: {},
            }).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    let info = data.data.goods;
                    state.info = info;
                    state.banner = info.thumbs || []
                    state.banner_list = []
                    state.banner_idx = 0
                    if(state.banner.length > 0) {
                        state.banner.forEach((v,k) =>{
                            if(k == 0 ||  k % 2 != 0) {
                                if(!state.banner_list[state.banner_idx]) {
                                    state.banner_list.push([v])
                                }else {
                                    state.banner_list[state.banner_idx].push(v)
                                }
                            }else {
                                state.banner_idx++
                                state.banner_list.push([v])
                            }
                        })
                    }
                    store.commit('setDetailImg', state.banner)
                    // 大图
                    state.img_big = info.thumbs && info.thumbs[0] ? info.thumbs[0]: ''

                    // 数据手册
                    // state.book_img = info.databook_images ? JSON.parse(info.databook_images) : []
                    state.book_img = info.databook_images ? info.databook_images : []

                    // 面包屑
                    state.first = info.first_category.name
                    state.first_id = info.first_category.id
                    state.second = info.second_category.name
                    state.second_id = info.second_category.id
                    state.pro = info.name

                    state.bread_flag = false,
                    nextTick(()=> {
                        state.bread_flag = true
                    })
                    state.min_num = info.min_num
                    state.num = state.stock < 1 ? '' : (state.min_num > 0 ? state.min_num : '')
                    change_buy(state.num)
                }
            });
        }
        get_info()

        let preview = () => {
            const {href} = router.resolve({path: '/preview'})
            window.open(href, '_blank')
        }

        // 数量
        let change_num = () => {
            
            // state.num = state.num.replace(/\D/g, "")
            // state.num = state.num.replace(/[^1-9]/g, "1");
            // state.info.purchase_limit = null
            // state.info.stock = 12
            // state.min_num = 10

            // if (Number(state.num) < Number(stock)) {
            //     if (state.num.length == 0) {
            //         state.num = 1;
            //     } else if (state.num.length == 1) {
            //         state.num = state.num.replace(/[^1-9]/g, "1");
            //     }
            // } else {
            //     state.num = stock;
            // }
            
            let purchase = Number(state.info.purchase_limit)
            let stock = Number(state.info.stock)
            let num = Number(state.num)
            let min_num = Number(state.min_num)
            let limit = purchase && purchase > 0 ? (stock > purchase ? purchase : stock) : stock

            if (num > limit) {
                state.num =  min_num*Math.floor(limit/min_num) //3*6
                ElMessage.warning(`最大订购量为${state.num}个，已为您修正。`)
            }else if(num <1 && stock > 0 || num < min_num )  {
                state.num = min_num;
                ElMessage.warning(`最小起订量为${state.num}个，已为您修正。`)
            }else if (stock <= 0) {
                state.num = '';
            }else {
                state.num = min_num*Math.floor(num/min_num) //3*6
                let ge_max = state.min_num*Math.ceil(num/state.min_num) // 3*7
                let ge_min = state.min_num*Math.floor(num/state.min_num) //  3*6
                state.num = ge_max < limit ? ge_max : ge_min 
                if(num % min_num !== 0) {
                    ElMessage.warning(`必须以${min_num}的倍数修改，已为您修正。`)
                  }
            }
            change_buy(state.num)
        }

        let num_add = () => {
            let limit = state.info.purchase_limit && state.info.purchase_limit > 0 ? (state.info.stock > state.info.purchase_limit ? state.info.purchase_limit : state.info.stock) : state.info.stock
            if (state.num <= Number(limit) - state.min_num) {
                  state.num += state.min_num;
                  change_buy(state.num);
              }
        }

        let num_reduce = () => {
            if (state.num >= state.min_num*2) {
                state.num-= state.min_num;
                change_buy(state.num)
            }
            
        }

        // 购买数量改变
        let change_buy = (num) => {
            postData({
                url: `api/shopping_car/set_num`,
                data: {
                    goods: state.id,
                    num: num,
                },
            }).then((res) => {
                let data = res.data
                if(data.code == 200) {
                    state.price = data.data.money
                }else {
                    state.price = 0

                }
            });
            
        }

        // 加入购物车
        let join = () => {
            if (state.userid) {
                postData({
                url: `/api/shopping_car/store`,
                data: {
                    goods_id: state.id,
                    num: state.num,
                },
                }).then((res) => {
                    let data = res.data;
                    if (data.code === 200) {
                        ElMessage.success(data.data.msg);
                        get_info();
                        state.num = 1;
                        store.commit('setCount');
                    } else if (data.code === 422) {
                        ElMessage.error(data.data.msg);
                    } else {
                        ElMessage.error(data.data.msg);
                    }
                });
            } else {
                store.commit("setLoginBox", true);
            }
        }

        // 立即购买
        let buy = () => {
            if (state.userid) {
                postData({
                    url: `/api/shopping_car/store`,
                    data: {
                        goods_id: state.id,
                        num: state.num,
                    },
                }).then((res) => {
                    let data = res.data;
                    if (data.code === 200) {
                        router.push({ path: "/car" });
                        window.scrollTo(100,0)
                    } else if (data.code === 422) {
                        ElMessage.error(data.data.msg);
                        store.commit('setCount');
                    } else {
                        ElMessage.error(data.data.msg);
                    }
                });
            } else {
                store.commit("setLoginBox", true);
            }
        }

        let rent = () => {
            if (state.userid) {
                state.rent_show = true;
            } else {
                store.commit("setLoginBox", true);
            }
        }

        let handleClose = () => {
            state.rent_show = false;
        }

        let to_smt = () => {
            router.push({path: '/smt'})
        }

        let handleView = (v) => {
            state.dialogImageUrl = v
            state.dialogVisible = true
        }

        let handleRemove = (v, k) => {
            state.form.thumbs.splice(k, 1)
        }

        let img_upload = (e) => {
            const isLt2M = e.file.size / 1024 / 1024 < 1;
                if (!isLt2M) {
                    ElMessage.error("上传图片大小不能超过 1MB!");
                    up.value.clearFiles();
                    return false;
            }
            const formData = new FormData();
            formData.append("name", e.file);
            formData.append("prefix", "chip");

            postData({
                url: "/api/upload/single",
                data: formData,
            }).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    state.form.thumbs.push(data.data.path_url);
                    up.value.clearFiles();
                
                } else {
                    ElMessage.error(data.data.msg);
                }
            });
        }

        let down_book = () => {
            window.open(state.info.databook)
        }

        let prev =  () => {
            sale.value.prev()
        }

        let next = () => {
            sale.value.next()
        }

        let change_img = (v) => {
            state.img_big = v
        }

        let store_error = () => {
            form_err.value.validate((valid) => {
                if (valid) {
                    postData({
                        url: `api/goods/store_error`,
                        data: {
                            goods_id: state.id,
                            locate: state.form.locate,
                            thumbs: state.form.thumbs,
                            desc: state.form.desc
                        },
                    }).then((res) => {
                        let data = res.data
                        if(data.code == 200) {
                            ElMessage.success(data.data.msg);
                            state.rent_show = false
                        }else {
                            ElMessage.error(data.data.msg);
                        }
                    });
                }
            })
        }

        let serve = () => {
            state.serve_show = true
        }

        onMounted(()=>{
            nextTick(()=>{
                window.scrollTo(100,0)
                let timer = setInterval(function () {
                    if(sale.value) {
                      sale.value.setActiveItem(0)
                    }
                    
                    if(book.value) {
                        book.value.setActiveItem(0)
                    }
                    clearInterval(timer)
                }, 1500)
            })

        })

        return {
            sale,
            book,
            ...toRefs(state),
            handleClose,
            rent,
            buy,
            join,
            num_reduce,
            num_add,
            change_num,
            get_info,
            to_smt,
            handleRemove,
            handleView,
            down_book,
            prev,
            next,
            change_img,
            store_error,
            form_err,
            img_upload,
            serve,
            up,
            preview,
            change_buy
        }
    },
    components: {
        topnav,
        foot,
        topmenu,
        topbread,
        Plus,
        toolbar,
        bigimg
    },
};
</script>

<style lang="less" scoped>
/deep/ .el-carousel__indicators--horizontal {
    display: none;
}
/deep/ .el-upload-list__item, /deep/ .el-upload  {
    height: 70px;
    width: 70px;
}
/deep/ .el-upload--picture-card i {
    margin-top: 20px;
}
.detail {
    background: #fff !important;
    padding: 154px 0 440px;

    /deep/ .el-tabs__nav-wrap {
        height: 50px;
        background: #f5f5f5;
        .el-tabs__nav-scroll {
        height: 100%;
        }
        .el-tabs__active-bar {
        height: 5px;
        background-color: #1C46B7;
        }
        .el-tabs__item {
        height: 50px;
        width: 118px;
        text-align: center;
        line-height: 50px;
        }
        .el-tabs__item.is-active {
        color: #1C46B7;
        }
        .el-tabs__item:hover {
        color: #1C46B7;
        }
    }

    .detail_wrap {
        padding: 20px 0 40px;
    }

    .dis {
        cursor: not-allowed;
    }

    .home_top {
        margin-bottom: 40px;
    }

    .home_banner {
        position: relative;
        width: 400px;
        margin-right: 20px;
        .big {
            height: 400px;
            width: 400px;
            margin-bottom: 25px;
            background: #fafafa;
            object-fit: contain;
        }
        .sample {
            position: absolute;
            top: 0;
            left: 0;
            height: 90px;
            width: 90px;
            z-index: 991;
        }
        .glass {
            position: absolute;
            top: 360px;
            right: 0;
            z-index: 991;
            height: 40px;
            width: 40px;
            text-align: center;
            line-height: 40px;
            background: #fff;
            cursor: pointer;
            .iconfont {
                font-size: 20px;
                color: #666;
            }
        }
        .detail_banner {
            .action {
                height: 107px;
                width:35px;
                background: rgba(112, 112, 112, 0.39);
                cursor: pointer;
                .iconfont {
                    color: #fff;
                }
            }
        }
        
        /deep/ .el-carousel__item {
            padding: 0 10px;
            overflow: hidden;
        }

        .show-img {
            flex: 1;
            padding: 0 30px;
            height: 107px;
            width: 100%;
            
        }
        .show-item {
            position: relative;
            height: 100%;
            width: 100%;

            .banner_img {
                height: 107px;
                width: 107px;
                background: #fafafa;
                object-fit: contain;
                cursor: pointer;
                opacity: 0.6;
            }

            .banner_img.active {
                opacity: 1;
                border: 1px solid #013EAA;
            }
        }
    }

    .home_info {
        flex: 1;
        .title {
            margin: 3px 0 0;
            font-size: 24px;
            font-weight: 600;
            line-height: 33px;
            color: #333;
            .name {
                margin-right:20px;
            }
            .tag {
                height: 28px;
                line-height: 28px;
                padding: 0 10px;
                background: #FF7F00;
                border-radius: 2px;
                font-size: 14px;
                font-weight: 600;
                text-align:center;
                color: #FFF;
                margin-right: 12px;
            }
            .dedu {
                height: 28px;
                line-height: 28px;
                border: 1px solid #1C46B7;
                border-radius: 2px;
                font-size: 14px;
                font-weight: 600;
                color: #1C46B7;
                padding: 0 10px;
                margin-left: 12px;
                margin-bottom: 20px;

            }
        }

        .board {
            display: flex;
            align-items: center;
            width: 100%;
            font-size: 13px;
            line-height: 30px;
            color: #999999;
            .board_title {
                width: 65px;
                font-size: 13px;
                color: #999999;
                font-weight: normal;
                text-align-last: justify;
            }
            .symbol {
                margin-right: 15px;
                margin-left:4px;
            }
            .ctx {
                color: #333;
            }
            .data_box {
                flex: 1;
            }
            .data {
                cursor: pointer;
                span {
                    margin-left: 5px;
                    font-size: 13px;
                    line-height: 18px;
                    color: #666666;
                    text-decoration: underline;
                }
                span:hover {
                  color: #1C46B7;
                }
            }
            .get {
                font-size: 12px;
                line-height: 17px;
                color: #999;
                span{
                    color: #1C46B7;
                }
            }
        }
        .start_box {
            flex-direction: column;
            display: flex;
            justify-content: center;
        }

        .start {
            line-height: 20px;
        }

        .normal {
            padding: 26px 24px 6px;
            align-items: flex-start;
            position: relative;
            background: #f5f5f5;
            margin:10px 0 14px;

            .price {
                font-size: 15px;
                line-height: 20px;
                color: #999;
                margin-right: 20px;
            }
            .price_list {
                li {
                    margin-bottom: 5px;
                    font-weight: 600;
                    line-height: 20px;
                }
            }
            .price_num {
                font-size: 13px;
                color: #333;
                margin-right: 10px;
            }
            .price_per {
                font-size: 15px;
                color:#FF7F23;
            }
            .store {
                position:absolute;
                right: 20px;
                bottom: 20px;
                font-size: 13px;
                line-height: 18px;
                color: #999;
            }
        }

        .num {  
            .num-box {
                margin: 0 9px 0 10px;

                input {
                    width: 61px;
                    height: 38px;
                    border: 1px solid #c9c9c9;
                    padding: 10px;
                    font-size: 16px;
                    font-weight: 600;
                    color: #333333;
                }

                .btns {
                    flex-direction: column;
                    button {
                        height: 19px;
                        line-height: 15px;
                        background: red;
                        width: 16px;
                        background: #ededed;
                        border: 1px solid #c9c9c9;
                        border-left: 0;
                        font-size: 16px;
                        font-weight: 600;
                        color: #b2b2b2;
                    }
                }
            }
            .num_buy {
                cursor: pointer;
                font-size: 12px;
                line-height: 17px;
                color: #1C46B7;
                .t-icon {
                    margin-right: 5px;
                }
            }
        }

        .num_total {
            margin-bottom: 20px;
            padding-left: 105px;
            font-size: 14px;
            color: #666;
            span {
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                color: #FF7F23;
            }
        }

        .tips {
            display: flex;
            align-items: flex-start;
            margin-bottom: 30px;
            i {
                font-size: 13px;
                color: #999;
                margin-right: 10px;
            }
            p{
                font-size: 11px;
                line-height: 16px;
                color: #999999;
                width: 600px;
            }
        }

        .show-btns {
            align-items: center;
            margin-bottom: 22px;
            button {
                width: 180px;
                height: 42px;
                background: #fff;
                border: 1px solid #1C46B7;
                font-size: 16px;
                font-weight: 600;
                line-height: 42px;
                color: #1C46B7;
                margin-right: 5px;
            }

            .add-btn {
                color: #ffffff;
                background: #1C46B7;
            }

            .rent {
                font-size: 12px;
                line-height: 17px;
                color: #1C46B7;
                text-decoration: underline;
                margin-left:20px;
                cursor: pointer;
            }
        }

        .show-other {
            display: flex;
            align-items: center;
            font-size: 12px;
            color: #999;
            span {
                color: #333;
                text-decoration: underline;
                margin: 0 2px;
                cursor: pointer;
            }
            span:hover {
                color:#1C46B7;
            }
        }
    }

    .home_goods {
        padding: 10px 20px 5px;

        .intro {
            border: 1px solid #EAE9EA;
            margin-bottom: 18px;
            padding: 20px 39px 20px 22px;
            display: flex;
            align-items: center;
            .intro_title {
                display: inline-block;
                font-size: 13px;
                font-weight: 400;
                line-height: 18px;
                color: #999999;
                width: 105px;
            }
            .intro_txt {
                font-size: 13px;
                font-weight: 400;
                line-height: 18px;
                color: #666666;
                margin-left: 40px;
            }
        }
        .data {
            border: 1px solid #EAE9EA;
            border-bottom: none;
            margin-bottom: 20px;
            .data_head {
                display: flex;
                align-items: center;
                justify-items: center;
                height: 42px;
                background: #F7F7F7;
                font-size: 13px;
                font-weight: 400;
                line-height: 18px;
                color: #666666;
                text-align: center;
            }
            .data_body {
                display: flex;
                align-items: center;
                justify-items: center;
                height: 42px;
                border-bottom: 1px solid #EAE9EA;
            }
            .data_title {
                height: 100%;
                width: 250px;
                padding-left: 55px;
                border-right: 1px solid #EAE9EA;
                font-size: 13px;
                font-weight: 400;
                color: #666666;
                line-height: 40px;
            }
            .data_val {
                height: 100%;
                line-height: 40px;
                flex: 1;
                text-align: center;
                font-size: 13px;
                font-weight: 400;
                color: #333333;
            }
        }
    }

    .book_img {
        height:100%;
        width: 100%;
        object-fit: contain;
        background: #fff;
    }

    .book_data {
        font-size: 16px;
        margin: 20px 0 80px;
        padding: 10px 50px;
        background: #F5F5F5;
        display:inline-block;
        cursor: pointer;
        .book_name {
            color: #333;
            margin-left: 14px;
        }
        .book_down {
            color: #1C46B7;
            cursor:pointer;
            margin-left: 5px;
        }
    }
}
.home_bottom {
    border: 1px solid #EBEBEB;
}
.bgc_empty {
    padding-top: 100px;
    text-align: center;

    img {
        width: 128px;
        height: 142px;
        margin-bottom: 20px;
    }

    p {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 17px;
        color: #333333;
    }
}
.demo-upload-list{
    float:left;
    display: inline-block;
    width: 70px;
    height: 70px;
    text-align: center;
    line-height: 70px;
    border: 1px solid transparent;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    position: relative;
    box-shadow: 0 1px 1px rgba(0,0,0,.2);
    margin-right: 10px;
}
.demo-upload-list img{
  width: 100%;
  height: 100%;
}
.demo-upload-list-cover{
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,.6);
}
.demo-upload-list:hover .demo-upload-list-cover{
  display: block;
}
.demo-upload-list-cover i{
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  margin: 0 2px;
}
.detail_up {
  float: left;
}
</style>

<style lang="less">
.modal_err {
    width: 422px;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.35);
    opacity: 1;
    border-radius: 8px;

    .el-dialog__header {
        padding: 18px 24px 24px;
        .dialog-title {
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
            color: #333333;
        }
    }
    .el-dialog__body{
        padding: 0 24px;
    }
    .el-dialog__footer {
        padding: 0 24px 18px;

    }

    .dialog-footer {
        .el-button {
            width: 68px;
            height: 32px;
            border: 1px solid #ddd;
            border-radius: 3px;
            font-size: 14px;
            line-height: 32px;
            color: #666666;
            padding: 0;
            background: #FAFAFA;

        }
        .sure {
            background: #1C46B7;
            color: #fff;
            border: 1px solid #1C46B7;
        }
    }

    .err_title {
        font-size: 12px;
        line-height: 17px;
        color: #999;
        margin-bottom:28px;
    }
    
    .dialog_img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .el-form-item {
        margin-bottom: 20px;

        .el-form-item__label {
            color: #666;
            font-size: 13px;
            text-align: right;
        }
        
        .el-input__inner {
            height: 32px;
            background: #FFFFFF;
            border: 1px solid #D1D1D1;
            border-radius: none!important;
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            color: #333333;
            padding: 7px 16px;
        }
        .el-select {
            width: 100%;
        }
        .el-textarea__inner {
            width: 294px;
            height: 79px;
            background: #FFFFFF;
            border: 1px solid #D1D1D1;
            resize: none;
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            color: #333333;
            padding: 7px 16px;
        }
    }
    
}

.modal_serve {
    width: 317px;
    height: 296px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    border-radius: 4px;

    .el-dialog__header {
        padding: 18px 24px 24px;
        .dialog-title {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        color: #333333;
        }
    }
    .el-dialog__body {
        padding: 0 24px;
    }

    .serve_img {
        height: 160px;
        width: 160px;
        margin-top:11px;
    }
}

</style>
